.bttn-flat {
    background-color: rgb(253, 164, 253);
    color: purple;
    border-radius: 5px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    
}


.bttn-xxl {
    padding: 1rem 1.5rem;
    font-size: 1.5rem;
}

.scroll {
    background-color: rgb(248, 248, 248);
    height: 440px;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: justify;
}