.hide-input {
    display: none;
}

.upload-button{
    width: 200px;
    height: 120px;
}

.upload-icon{
    width: 60px;
    height: 50px;
    fill: red;
}