.separator {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .line {
    flex-grow: 1;
    height: 1px;
    background-color: #eee; /* Grey color for the line */
  }
  
  .text {
    padding: 0 16px;
    color: #ccc; /* Color for the text */
  }
  