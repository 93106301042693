.background-img {
  background-image: url("/public/elegant_dark_blue_low_res.png");
  background-size: 1600px auto;
  
  background-repeat: no-repeat;
}
  
.main-row {
  height: 850;
}

.signup-button {
  position: absolute;
  bottom: 25vh;
}