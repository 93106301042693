.divider{
    width: 1px;
    height: 80%;
    color: black;
}

.logo{
    object-fit: cover;
}

.mandatory{
    color:red;
}

.signup-button{
    background-color:greenyellow;
}

.error{
    color: red;
}

.success{
    color: green;
}