hr{
    margin-inline: -40px;
    border-width: 2px;
    color: lightgrey;
}

.light-title{
    color: grey;
}

.time-title{
    color: #66BB6A;
}


.time-title-option{
    color: #66BB6A;
    font-weight: 500;
    
}

.standard-option{
    color: #2196f4;
}

.verified-option{
    color: #f57c00;
}

.audible-option{
    color: #FF9900;
}

.kindle-option{
    color: #66BB6A;
}