.sidebar{
    height: 100vh;
    z-index: 98;
}

.menu-text{
    
    font-size: large;
    margin-top: 25px;
    color: black;
}