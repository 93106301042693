.navbar-container {
    background-color: #180344;
    
}

.brand-text{
    color: white;
    
}


.points-text{
    color: white;
    margin-top: 8px;
}