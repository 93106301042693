
.hr{
    border-width: 2px;
    color: gray;
}
.library-logo{
    height: 110px;
    width: 220px;
}

.cover-image{
    width: 100px;
}

.points{
    color: orangered;
}

.title-text{
    color: #2a98f3;
    font-weight: 600;
}

.grey-text{
    color: gray;
    width: 600;
}

.date-text{
    color: #56AB5A;
}

.price-text{
    color: #f57c00;
    width: 500;
}
.book-card{
    border-width: 4px;
}
.book-card:hover{
    border-width: 4px;
    border-color: #2a98f3;
}

.display-none{
    display: none;
}

.display-block{
    display: block;
    margin-top: -37px;
}

.pick-button{
    width: 120px;
}