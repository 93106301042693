.title-text{
    color: #2a98f3;
    font-weight: 1500;
}

.review-button{
    background-color: #f57c00;
}

.edit-button{
    background-color: #2196f4;
}

.review-button-text{
    color: #f57c00;
}

.edit-button-text{
    color: #2196f4;
}

.review-text{
    color: #2196f4;
}

.rating-text{
    color: #66BB6A;
}

.pointer-card{
    cursor: pointer;
    transition: transform 0.3s ease;
}

.pointer-card:hover {
    transform: scale(1.05);
  }