
.navbar-container {
    background-color: #fff;
}

.sidebar-col {
    padding: 0;
    margin: 0;
    position: fixed;
}

.outlet-col{
    top: 220px;
    margin-left: 238px;
}

.app-content{
    margin-top: 44px;
    margin-left: 0px;
}

.footer-section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1000px;
    margin: 0 auto;
}