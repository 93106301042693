.head{
    height: 60px;
    width: 80%;
    padding-top: 0px;
    padding-inline: 180px;
    margin-top: -7px;
    background-color: white;
    box-shadow: 0px 1px 1px 0 rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.19);
    position:fixed;
    z-index: 99;
}

.page-container{
    padding-inline: 180px;
    padding-top: 60px;
}

.remove-button{
    color: red;
}

.info-text{
    font-size:14px;
}

.light-text{
    color: grey;
    font-weight: 400;
}

.light-text-thick{
    color: grey;
    font-weight: 600;
}

.completion-text{
    color: #2a98f3;
    font-weight: 500;
}

.standard-checkbox{
    color: red;
}
#custom-checkbox.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
    background-color: blue !important; /* Change the fill color of the checked checkbox */
    border-color: red !important;    /* Change the border color of the checked checkbox */
  }

.customCard{
    border: none !important;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15); /* Add shadow */
}