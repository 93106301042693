.day-counter {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
  }
  
  .day-item {
    width: 20%;
    background-color: #f2f2f2;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    margin-inline: 10px;
    box-sizing: border-box;
    text-align: center;
    position: relative;
  }
  
  .day-item.due {
    background-color: #ffbdbd;
  }
  
  .day-item.due::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #ff4747;
    z-index: -1;
  }
  
  .day-number {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    margin-bottom: 5px;
  }
  
  .due-label {
    position: absolute;
    bottom: -15px;
    left: 0;
    width: 100%;
    background-color: #ff4747;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    padding: 5px 0;
    border-radius: 0 0 5px 5px;
  }